@mixin content-limit($line) {
  white-space: normal;
  display: -webkit-box !important;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

$c_primary: #1d1d1d;
$c_secondary: #141414;
$c_body: #6a6a6a;
$c_heading: #141414;
$c_danger: #d92d20;
$c_success: #079455;
$c_border: #e6e6e6;
$c_grey: #ebebeb;
$c_black: #000000;
$c_white: #ffffff;
$f_body: 'Urbanist', sans-serif;
$transition: all 0.3s ease-in-out 0s;
$shadow1: 0px 20px 16px #00000014;
$shadow2: 0px 2px 16px #00000014;
